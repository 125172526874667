import {
  Departure,
  DepartureStatus,
  ExtrasCostAmounts,
  MultiCurrencyAmounts,
  PassengerCostAmounts,
  UserRole,
} from '@flashpack/graphql';
import { TableHeader, TableRow, TableRowContextOption } from './Table';
import { CopyPasteClipboardIcon } from 'design-system';
import { format, parseISO } from 'date-fns';
import { deepStripTypename } from '@src/itinerary/activity/common/activityFormMapper';
import { CostTypeLocked } from '@src/itinerary/costs-page/utils';

export const pasteFromAbove: TableRowContextOption = {
  label: 'Paste From Above',
  key: 'paste-from-above',
  icon: <CopyPasteClipboardIcon sx={{ height: '18px' }} />,
  onClick: (targetRowIndex, table, setFormValues) => {
    const aboveRowIndex = targetRowIndex - 1;
    if (aboveRowIndex >= 0) {
      setFormValues({
        ...table,
        rows: table.rows.map((row, index) => {
          if (index === targetRowIndex) {
            return {
              ...table.rows[targetRowIndex],
              values: table.rows[aboveRowIndex].values,
            };
          }
          return row;
        }),
      });
    }
  },
};

export const notLockedMultiCurrencyHeaders: TableHeader[] = [
  { name: 'amountUSD', label: '🇺🇸 USD', locked: false },
  { name: 'amountGBP', label: '🇬🇧 GBP', locked: false },
  { name: 'amountEUR', label: '🇪🇺 EUR', locked: false },
  { name: 'amountHKD', label: '🇭🇰 HKD', locked: false },
  { name: 'amountAUD', label: '🇦🇺 AUD', locked: false },
  { name: 'amountAED', label: '🇦🇪 AED', locked: false },
  { name: 'amountNZD', label: '🇳🇿 NZD', locked: false },
  { name: 'amountCAD', label: '🇨🇦 CAD', locked: false },
];

type MinimalDeparture = Partial<Departure> & {
  id: string;
  date: string;
  status: DepartureStatus;
  tripPrice?: MultiCurrencyAmounts | null;
  earlyBirdDiscount?: MultiCurrencyAmounts | null;
  passengerCost?: PassengerCostAmounts | null;
  extrasCost?: ExtrasCostAmounts | null;
  passengerCostsLocked?: boolean;
  extrasCostsLocked?: boolean;
  mandatoryCostsLocked?: boolean;
};

const departureToTableRow: (departure: MinimalDeparture, lockRow: boolean) => TableRow = (
  departure,
  lockRow,
) => {
  const departureDate = parseISO(departure.date);
  let rowValues: Record<string, number | null> = {};
  if (departure.earlyBirdDiscount) {
    rowValues = deepStripTypename(departure.earlyBirdDiscount);
  }
  if (departure.tripPrice) {
    rowValues = deepStripTypename(departure.tripPrice);
  }
  if (departure.promotionalDiscount) {
    rowValues = deepStripTypename(departure.promotionalDiscount);
  }
  if (departure.passengerCost) {
    rowValues = deepStripTypename(departure.passengerCost);
  }
  if (departure.extrasCost) {
    rowValues = deepStripTypename(departure.extrasCost);
  }
  if (departure.mandatoryCost) {
    rowValues = deepStripTypename(departure.mandatoryCost);
  }
  const row: TableRow = {
    name: departure.id,
    label: format(departureDate, 'dd MMM yyyy'),
    locked: lockRow,
    status: departure.status,
    values: rowValues,
    meta: { departureDate: departure.date },
  };
  return row;
};

export const departuresToTableRows: (
  departures: MinimalDeparture[],
  shouldLockRowPredicate: (departure: MinimalDeparture) => boolean,
) => TableRow[] = (departures, shouldLockRowPredicate) => {
  return departures
    .slice()
    .sort((a, b) => {
      return parseISO(a.date).getTime() - parseISO(b.date).getTime();
    })
    .map((departure) =>
      departureToTableRow(departure, shouldLockRowPredicate(departure)),
    );
};

export const lockCostsFactory = (
  entity: CostTypeLocked,
): ((departure: MinimalDeparture) => boolean) => {
  return (departure) => {
    return departure[entity] || false;
  };
};

export const lockPricesAndDiscountsFactory = (
  userRole: UserRole | undefined,
): ((departure: MinimalDeparture) => boolean) => {
  const userIsSuperuser = userRole === UserRole.Superuser;
  return (departure) => {
    const departureDate = parseISO(departure.date);
    return !userIsSuperuser || departureDate < new Date();
  };
};

export const showTableOpenMenuButton = (
  row: TableRow,
  rowIndex: number,
  rowOptions: TableRowContextOption[],
) => {
  return rowOptions.length > 0 && !row.locked && rowIndex !== 0;
};

import { useMutation, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';
import { useFxRatesWithDecimalsQuery } from '@src/finance/useFxRatesWithDecimalsQuery';
import {
  ItineraryWithTripPricesDocument,
  Scalars,
  UpdateDeparturePricesDocument,
  UserRole,
} from '@flashpack/graphql';
import { FinanceEntity } from '@src/shared/finance/utils';
import Table, {
  TableData,
  TableRow,
  TableRowContextOption,
} from '@src/shared/table/Table';
import {
  departuresToTableRows,
  pasteFromAbove,
  showTableOpenMenuButton,
  lockPricesAndDiscountsFactory,
} from '@src/shared/table/utils';
import { useToast } from '@src/shared/toast/useToast';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import Decimal from 'decimal.js';
import { ReactNode, useMemo, useState } from 'react';
import { BulkUpdatePricesModal } from './BulkUpdatePricesModal';

const lockedPricesMessage =
  'The pricing table is read-only because you do not have permission to edit.';

export const TripPricesTable = ({
  renderCanton,
}: {
  renderCanton: (hasPendingChanges: boolean) => ReactNode;
}) => {
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState<boolean>(false);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);
  const { error: errorToast, success: successToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const { itineraryId } = useRequiredParams(['itineraryId']);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const fx = useFxRatesWithDecimalsQuery();
  const itinerary = useQuery(ItineraryWithTripPricesDocument, {
    variables: {
      id: itineraryId,
    },
  });

  const { currentUser } = useAuthorization();
  const userIsSuperuser = currentUser?.role === UserRole.Superuser;
  const [updateDeparturePrices] = useMutation(UpdateDeparturePricesDocument);

  // Fetch departure pricing.
  const pricingData: TableData = useMemo(() => {
    const departures = itinerary.data?.itinerary?.departures ?? [];

    return {
      headers: [
        { name: 'amountUSD', label: '🇺🇸 USD', locked: false },
        { name: 'amountGBP', label: '🇬🇧 GBP', locked: true },
        { name: 'amountEUR', label: '🇪🇺 EUR', locked: true },
        { name: 'amountHKD', label: '🇭🇰 HKD', locked: true },
        { name: 'amountAUD', label: '🇦🇺 AUD', locked: true },
        { name: 'amountAED', label: '🇦🇪 AED', locked: true },
        { name: 'amountNZD', label: '🇳🇿 NZD', locked: true },
        { name: 'amountCAD', label: '🇨🇦 CAD', locked: true },
      ],
      rows: departuresToTableRows(
        departures,
        lockPricesAndDiscountsFactory(currentUser?.role),
      ),
    };
  }, [itinerary, currentUser]);

  if (fx.error) {
    return <Typography variant="pageHeader">Error fetching FX rates</Typography>;
  }

  const rowOptions: TableRowContextOption[] = [pasteFromAbove];

  const handleSubmitPricingAmounts = async (formValues: TableData) => {
    setIsSubmitting(true);
    await safeMutation(
      updateDeparturePrices({
        variables: {
          input: formValues.rows
            .filter((row) => row.values.amountUSD != undefined && !row.locked)
            .map((row) => {
              return {
                departureId: row.name,
                amountUSD: row.values.amountUSD as number,
              };
            }),
        },
        refetchQueries: [ItineraryWithTripPricesDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          successToast('Trip prices updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update trip prices');
        },
      },
    );
  };

  // Handle query FX rates
  const handleRowChanged = (row: TableRow) => {
    const pricingRate = fx.data?.fxRates.pricing;
    const priceUSD = row.values.amountUSD;
    if (!priceUSD || !pricingRate) {
      row.values = {};
      return row;
    }
    row.values.amountGBP = roundUpToFive(pricingRate.rateGBP.mul(priceUSD));
    row.values.amountEUR = roundUpToFive(pricingRate.rateEUR.mul(priceUSD));
    row.values.amountHKD = roundUpToFive(pricingRate.rateHKD.mul(priceUSD));
    row.values.amountAUD = roundUpToFive(pricingRate.rateAUD.mul(priceUSD));
    row.values.amountAED = roundUpToFive(pricingRate.rateAED.mul(priceUSD));
    row.values.amountNZD = roundUpToFive(pricingRate.rateNZD.mul(priceUSD));
    row.values.amountCAD = roundUpToFive(pricingRate.rateCAD.mul(priceUSD));
    return row;
  };

  function roundUpToFive(amount: Decimal): number {
    return amount.dividedBy(5).ceil().mul(5).toNumber();
  }

  // Render table modelling.
  return (
    <>
      {!userIsSuperuser && (
        <WarningMessage message={lockedPricesMessage} iconType="LOCKED" />
      )}
      <BulkUpdatePricesModal
        open={openBulkUpdateModal}
        onClose={() => setOpenBulkUpdateModal(false)}
        selectedDepartureIds={selectedDepartureIds}
      />
      <Table
        initialData={pricingData}
        handleSubmit={handleSubmitPricingAmounts}
        handleRowChanged={handleRowChanged}
        rowOptions={rowOptions}
        editable={true}
        isLoading={isSubmitting || fx.loading || itinerary.loading}
        showOpenMenuButton={showTableOpenMenuButton}
        isRequired={false}
        renderCanton={renderCanton}
        submitLabel={'Save Trip Costs'}
        tableFormId="prices-table"
        entity={FinanceEntity.PRICE}
        selectedRowIds={selectedDepartureIds}
        setSelectedRowIds={setSelectedDepartureIds}
        setOpenBulkUpdateModal={setOpenBulkUpdateModal}
      />
    </>
  );
};

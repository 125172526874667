import { FC, memo, useState } from 'react';
import {
  Button,
  Skeleton,
  TableCell,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import isEqual from 'lodash.isequal';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField } from 'mui-rff';
import { ValidatorFactory } from '@src/design-system/forms/validators';
import { FormParser } from '@src/design-system/forms/parsers';
import { flashPackTheme } from '@src/design-system/theme';

interface TableBodyCellPropTypes {
  name: string;
  loading: boolean;
  editable: boolean;
  isLast: boolean;
  required: boolean;
  tooltipValue: string;
  clickHandler: () => void;
  value: number | null;
}

const TableBodyCell: FC<TableBodyCellPropTypes> = ({
  name,
  loading,
  editable,
  isLast,
  required,
  tooltipValue,
  clickHandler,
  value,
}) => {
  /*
    isEditting logic is used to render information without rendering the input field.
    Rendering an input field is costly and slows down table load, only when the user
    needs to edit the field should we render the input field. This swap is handled by
    the isEditting state.
  */
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const buttonClickHandler = () => {
    if (editable) {
      setIsEditing(true);
    } else {
      clickHandler();
    }
  };

  if (loading) {
    return (
      <TableCellWrapper isEditable={editable} isLoading={loading}>
        <Typography variant="bodySingle" paddingX={flashPackTheme.spacing(1)}>
          <Skeleton variant="text" />
        </Typography>
      </TableCellWrapper>
    );
  }

  return (
    <TableCellWrapper isEditable={editable} isLast={isLast} isLoading={loading}>
      <Typography variant="bodySingle">
        <StyledTooltip
          title={tooltipValue}
          readOnly={!editable}
          enterNextDelay={300}
          enterDelay={500}
          followCursor
        >
          <Box
            style={{
              borderRight: isLast ? 'none' : '1px solid #E0E0E0',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: flashPackTheme.spacing(0.5),
              paddingLeft: flashPackTheme.spacing(0.5),
            }}
          >
            {editable && isEditing ? (
              <TableCellInputField
                name={name}
                onFocus={(event) => event.target.select()}
                onClick={editable ? undefined : clickHandler}
                disabled={!editable}
                required={required}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                fieldProps={{
                  validate: required
                    ? ValidatorFactory.createRequired('required', 0)
                    : undefined,
                  parse: FormParser.parseNumber,
                }}
                data-testid="table-cell-input"
                autoFocus
              />
            ) : (
              <Button
                onClick={buttonClickHandler}
                sx={{
                  backgroundColor: editable
                    ? flashPackTheme.palette.principal.white
                    : flashPackTheme.palette.principal.grey30,
                  color: flashPackTheme.palette.principal.black,
                  cursor: editable ? 'text' : 'default',
                  // Hover color to match background when the price/cost is locked
                  ':hover': {
                    backgroundColor: flashPackTheme.palette.principal.grey30,
                  },
                }}
                // Incase a user tabs onto the button, we want to make sure it switches to edit mode
                onFocus={() => setIsEditing(true)}
                data-testid={`table-cell-${name}`}
              >
                {value}
              </Button>
            )}
          </Box>
        </StyledTooltip>
      </Typography>
    </TableCellWrapper>
  );
};

interface TableWrapperCellProps {
  isLast?: boolean;
  isEditable?: boolean;
  isLoading?: boolean;
}

const TableCellWrapper = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== 'isLast' && prop !== 'isEditable' && prop !== 'isLoading',
})<TableWrapperCellProps>(({ theme, isEditable, isLoading }) => ({
  maxHeight: '40px',
  minWidth: '60px',
  padding: '0px',
  borderBottom: '0px',
  backgroundColor: isEditable
    ? isLoading
      ? 'transparent'
      : theme.palette.principal.white
    : theme.palette.principal.grey30,
  input: {
    textAlign: 'center',
    padding: 0,
  },
}));

const TableCellInputField = styled(TextField)(({ theme }) => ({
  borderRadius: '2px',
  height: '40px',
  borderWidth: 0,
  padding: 0,
  border: 'none',
  input: {
    '&:hover': {
      backgroundColor: theme.palette.principal.grey30,
      borderRadius: '2px',
    },
  },

  '& fieldset': {
    border: 'none',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '10px',
    lineHeight: '10px',
    width: '100%',
    textAlign: 'center',
  },
}));

interface StyledTooltipProps {
  readOnly: boolean;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))<StyledTooltipProps>(({ theme, readOnly }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: readOnly
      ? theme.palette.principal.grey50
      : theme.palette.principal.grey30,
    borderRadius: theme.spacing(0.25),
  },
}));

export const MemoizedTableBodyCell = memo(TableBodyCell, (prevProps, nextProps) => {
  const { clickHandler, ...rest } = prevProps;
  const { clickHandler: nextClickHandler, ...nextRest } = nextProps;
  return isEqual(rest, nextRest);
});

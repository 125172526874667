import { Container, Drawer, Skeleton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import {
  ItineraryDeparturesDocument,
  AddDeparturesToItineraryDocument,
  AdventureWithItineraryTimelineDurationsDocument,
  AdventureWithItineraryAndDepartureTimelinesDocument,
} from '@flashpack/graphql';
import { useTheme } from '@mui/material/styles';
import {
  ProposeDeparturesForm,
  ProposeDepartureFormValues,
} from './ProposeDeparturesForm';
import { formatISO } from 'date-fns';
import { useToast } from '@src/shared/toast/useToast';
import { ApolloError, useMutation, useQuery } from '@apollo/client';

interface PropTypes {
  adventureId: string;
  onClose: () => void;
  open: boolean;
}

export const ProposeDeparturesOnAdventureDrawer: FC<PropTypes> = ({
  onClose,
  adventureId,
  open,
}) => {
  const [addDeparturesToItinerary] = useMutation(AddDeparturesToItineraryDocument, {
    refetchQueries: [
      ItineraryDeparturesDocument,
      AdventureWithItineraryAndDepartureTimelinesDocument,
    ],
  });

  const { data, loading } = useQuery(AdventureWithItineraryTimelineDurationsDocument, {
    variables: { adventureId },
  });

  const [selectedItineraryId, setSelectedItineraryId] = useState<string | undefined>(
    undefined,
  );

  const theme = useTheme();
  const { success, error: errorToast } = useToast();
  if (loading) {
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: 1200,
            padding: theme.spacing(2),
          },
        }}
      >
        <Container sx={{ p: 5 }}>
          <Container maxWidth="md">
            <Typography variant="subHeader" align="center">
              Propose departures
            </Typography>
            <Skeleton variant="text" width="25%" sx={{ fontSize: '30px' }} />;
          </Container>
          <Skeleton variant="rectangular" height={200} />
        </Container>
      </Drawer>
    );
  }

  const getItineraryDurationDays = (itineraryId?: string) => {
    const itinerary = data?.adventure.itineraries.find(
      (itinerary) => itinerary.id === itineraryId,
    );
    return itinerary ? itinerary.timeline.durationDays : 0;
  };

  const getItinerarylongestAccommodationDurationDays = (itineraryId?: string) => {
    const itinerary = data?.adventure.itineraries.find(
      (itinerary) => itinerary.id === itineraryId,
    );
    return itinerary ? itinerary.timeline.longestAccommodationDurationDays : 0;
  };

  const itineraryOptions = data?.adventure.itineraries
    .filter((i) => !i.archived)
    .map((i) => ({
      title: i.title,
      id: i.id,
    }));

  const onProposeDepartures = async (values: ProposeDepartureFormValues) => {
    const departures = values.departureDates.map((date) => ({
      date: formatISO(date, { representation: 'date' }),
      roomReleaseDays: values.roomRelease,
    }));

    try {
      await addDeparturesToItinerary({
        variables: {
          input: {
            itineraryId: values.itineraryId!,
            departures,
            privateGroup: values.privateGroup,
          },
        },
      });
      success('Departures proposed');
    } catch (e) {
      const error = e as ApolloError;
      errorToast(error.message || 'Error occurred, departures were not created');
    } finally {
      onClose();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 1200,
          padding: theme.spacing(2),
        },
      }}
    >
      <Container sx={{ p: 5 }}>
        <Container maxWidth="md">
          <Typography variant="subHeader" align="center">
            Propose departures
          </Typography>
          <Typography variant="pageHeader">
            {data?.adventure.title} ({data?.adventure.bookingEngineTourCode})
          </Typography>
        </Container>
        <ProposeDeparturesForm
          onSubmit={onProposeDepartures}
          onCancel={onClose}
          itineraryOptions={itineraryOptions}
          handleSelectItinerary={(itineraryId) => setSelectedItineraryId(itineraryId)}
          existingDepartures={
            data?.adventure.itineraries.map((i) => i.departures).flat() ?? []
          }
          itineraryDurationDays={getItineraryDurationDays(selectedItineraryId)}
          itineraryLongestAccommodationDurationDays={getItinerarylongestAccommodationDurationDays(
            selectedItineraryId,
          )}
        />
      </Container>
    </Drawer>
  );
};

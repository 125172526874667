import { Stack, Typography, useTheme, InputLabel } from '@mui/material';
import type { FilterOptionsState } from '@mui/material/useAutocomplete';
import { Box, WarningIcon } from 'design-system';
import { orderedCallingCodes } from './phoneNumberUtils';

import {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js';

import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { TextInput, TextInputProps } from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';

interface PhoneExtensionOption {
  id: string;
  value: string;
  label: string;
  secondaryLabel: string;
}

const options = orderedCallingCodes.map<PhoneExtensionOption>((countryCallingData) => ({
  id: countryCallingData.id,
  value: countryCallingData.callingCode,
  label: countryCallingData.callingCode,
  secondaryLabel: countryCallingData.countryName,
}));

const getOptionUniqueKey = (option: PhoneExtensionOption) => option.id;

type ChildTextInputProps = Omit<
  TextInputProps,
  'name' | 'helperText' | 'description' | 'extraDescription' | 'label'
> & { name?: string };

export interface PhoneNumberFieldProps {
  name?: string;
  value: string;
  setValue: (newValue: string) => void;
  label?: string;
  error?: boolean;
  helperText?: string;
  description?: string;
  extraDescription?: string;
  defaultCallingCode: string;
  callingCodeInputProps?: ChildTextInputProps;
  numberInputProps?: ChildTextInputProps;
}

export const PhoneInput: FC<PhoneNumberFieldProps> = (props) => {
  const {
    name,
    label,
    value,
    setValue,
    error,
    defaultCallingCode,
    helperText,
    description,
    extraDescription,
    callingCodeInputProps,
    numberInputProps,
  } = props;
  const [callingCode, setCallingCode] = useState(defaultCallingCode);
  const [nationalPhoneNumber, setNationalPhoneNumber] = useState('');
  const theme = useTheme();

  useEffect(() => {
    const formatted = formatIncompletePhoneNumber(value);
    const resolvedCallingCode = value ? formatted.split(' ')[0] : defaultCallingCode;
    setCallingCode(resolvedCallingCode);

    const nationalPhoneNumber = formatted.split(' ').slice(1).join('');
    setNationalPhoneNumber(nationalPhoneNumber);
  }, [value, defaultCallingCode]);

  const onNationalNumberChange = (newNationalPhoneNumber: string) => {
    setValue(parseIncompletePhoneNumber(`${callingCode}${newNationalPhoneNumber}`));
  };

  const onCallingCodeChange = (newCallingCode: string) => {
    setValue(parseIncompletePhoneNumber(`${newCallingCode}${nationalPhoneNumber}`));
  };

  const formatted = formatIncompletePhoneNumber(value);
  const formattedNationalNumber = formatted.split(' ').slice(1).join(' ');

  const labelColor = useMemo(() => {
    if (error) {
      return theme.palette.system.red100;
    }
    return theme.palette.principal.black;
  }, [error, theme]);

  const callingCodeOption = useMemo(() => {
    return options.find((option) => option.value === callingCode);
  }, [callingCode]);

  const filterOptions = useCallback(
    (
      options: PhoneExtensionOption[],
      state: FilterOptionsState<PhoneExtensionOption>,
    ) => {
      return options.filter(
        (option) =>
          option.label.toLowerCase().includes(state.inputValue.toLowerCase()) ||
          option.secondaryLabel.toLowerCase().includes(state.inputValue.toLowerCase()),
      );
    },
    [],
  );

  return (
    <Box>
      {label && (
        <Typography
          variant="Body S"
          sx={{ textTransform: 'none', paddingBottom: 0.5, color: labelColor }}
        >
          {error && (
            <WarningIcon
              sx={{
                fontSize: '12px',
                top: '1px',
                position: 'relative',
                marginRight: 0.25,
              }}
            />
          )}
          {label}
        </Typography>
      )}
      {description && (
        <InputLabel sx={{ marginBottom: 0.5 }} id={`description-${props.name}`}>
          <Typography
            variant="Additional"
            color={'grey70'}
            sx={{ textTransform: 'none' }}
          >
            {description}
          </Typography>
        </InputLabel>
      )}
      <Stack display={'flex'} flexDirection={'row'} gap={1}>
        <Dropdown<PhoneExtensionOption, false, true>
          name="phone-number"
          disabled={!!callingCodeInputProps?.disabled}
          disableClearable={true}
          TextInputProps={{
            type: 'tel',
            error,
            sx: {
              width: '95px',
            },
            inputProps: {
              autoComplete: 'nope',
            },
            ...callingCodeInputProps,
          }}
          data-testid="phone-country-code-dropdown"
          onChange={(_, option) => onCallingCodeChange(option?.value)}
          value={callingCodeOption}
          options={options}
          getOptionKey={getOptionUniqueKey}
          filterOptions={filterOptions}
        />
        <TextInput
          name={name || 'phone-number'}
          fullWidth
          value={formattedNationalNumber}
          onChange={(e) => onNationalNumberChange(e.target.value)}
          {...numberInputProps}
          error={error}
          InputProps={{
            ...numberInputProps?.InputProps,
          }}
        />
      </Stack>
      {extraDescription && (
        <InputLabel sx={{ marginY: 0.5 }} id={`extra-description-label-${name}`}>
          <Typography
            variant="Additional"
            color={'grey70'}
            sx={{ textTransform: 'none', fontStyle: 'italic' }}
          >
            {extraDescription}
          </Typography>
        </InputLabel>
      )}
      {error && helperText && (
        <InputLabel sx={{ marginTop: 0.5 }} id={`helper-text-label-${name}`}>
          <Typography
            variant="Additional"
            color={'system.red100'}
            sx={{ textTransform: 'none' }}
          >
            {helperText}
          </Typography>
        </InputLabel>
      )}
    </Box>
  );
};

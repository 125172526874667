import { Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { flashPackTheme } from '@src/design-system/theme';
import { Validator } from '@src/design-system/forms/validators';
import { FormParser } from '@src/design-system/forms/parsers';
import { pluralDepartures } from '@src/departures/utils';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useMutation } from '@apollo/client';
import {
  BulkUpdateDeparturePricesDocument,
  DepartureFinancesQueryDocument,
  ItineraryWithTripPricesDocument,
  Scalars,
  UserRole,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  selectedDepartureIds: Array<Scalars['UUID']>;
  initialValues?: BulkUpdatePricesModalFormType['tripPrice'];
}

type BulkUpdatePricesModalFormType = {
  tripPrice: number;
};

export const BulkUpdatePricesModal: FC<PropTypes> = ({
  open,
  onClose,
  selectedDepartureIds,
  ...props
}) => {
  const { currentUser } = useAuthorization();
  const { safeMutation } = useSafeMutation();
  const [bulkUpdateDeparturePrices] = useMutation(BulkUpdateDeparturePricesDocument);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { error: errorToast, success: successToast } = useToast();
  const numberOfDepartures = selectedDepartureIds.length;
  const title = `Update ${pluralDepartures(numberOfDepartures)}?`;

  const handleBulkSubmitPricingAmounts = async ({
    tripPrice,
  }: BulkUpdatePricesModalFormType) => {
    if (!tripPrice) {
      errorToast('Please enter a trip price');
    }
    setIsSubmitting(true);
    await safeMutation(
      bulkUpdateDeparturePrices({
        variables: {
          input: {
            departureIds: selectedDepartureIds,
            amountUSD: tripPrice,
          },
        },
        refetchQueries: [ItineraryWithTripPricesDocument, DepartureFinancesQueryDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          onClose();
          successToast('Trip prices updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update trip prices');
        },
      },
    );
  };

  return (
    <ConfirmationDialog
      {...props}
      open={open}
      onCancel={onClose}
      title={title}
      maxWidth={'sm'}
      cancelLabel="Cancel"
      confirmLabel="Update"
      confirmLoading={isSubmitting}
      confirmFormId="bulk-update-trip-prices-modal" // Form ID to connect to submit button
      disableConfirmButton={currentUser?.role !== UserRole.Superuser}
    >
      <Typography variant="captionPara" color={flashPackTheme.palette.principal.grey70}>
        Prices are all converted from 🇺🇸USD (United States Dollar)
      </Typography>

      <Form<BulkUpdatePricesModalFormType>
        onSubmit={handleBulkSubmitPricingAmounts}
        initialValues={{
          tripPrice: props.initialValues,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={(v) => void handleSubmit(v)} id="bulk-update-trip-prices-modal">
            <Typography variant="bodySingle">Trip Price (in 🇺🇸USD)</Typography>
            <TextField
              autoFocus
              name="tripPrice"
              required={true}
              disabled={currentUser?.role !== UserRole.Superuser || isSubmitting}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              fieldProps={{
                validate: Validator.required,
                parse: FormParser.parseNumber,
              }}
              sx={{
                width: flashPackTheme.spacing(10),
                paddingY: flashPackTheme.spacing(1),
              }}
            />
          </form>
        )}
      />
    </ConfirmationDialog>
  );
};

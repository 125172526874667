import React from 'react';
import { styled } from '@mui/material';
import { Typography, TypographyProps } from 'design-system';

type PillVariant = 'standard' | 'success' | 'warning' | 'error';

interface PillProps extends Omit<TypographyProps, 'variant'> {
  variant?: PillVariant;
  textVariant?: TypographyProps['variant'];
}

export const Pill: React.FC<PillProps> = ({
  variant = 'standard',
  textVariant = 'Body M',
  children,
  ...props
}) => {
  return (
    <StyledTypography pillVariant={variant} variant={textVariant} {...props}>
      {children}
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'pillVariant',
})<{ pillVariant: PillVariant }>(({ theme, pillVariant }) => ({
  borderRadius: '16px',
  textTransform: 'none',
  padding: '2px 10px',
  fontWeight: 'normal',
  maxWidth: 'fit-content',
  maxHeight: 'fit-content',
  color: theme.palette.text.primary,

  ...(pillVariant === 'standard' && {
    backgroundColor: theme.palette.principal.grey30,
  }),
  ...(pillVariant === 'success' && {
    backgroundColor: theme.palette.system.green10,
  }),
  ...(pillVariant === 'warning' && {
    backgroundColor: theme.palette.system.amber30,
  }),
  ...(pillVariant === 'error' && {
    backgroundColor: theme.palette.system.red10,
  }),
}));

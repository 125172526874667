import React from 'react';
import { styled } from '@mui/material/styles';
import { Autocomplete, AutocompleteProps, Paper } from '@mui/material';
import { BaseInput } from '../BaseInput';
import { TextInputProps } from '../TextInput/TextInput';
import { Box } from '../../containers';
import { OpenDropdown } from '../../icons';
import DropdownOption, { DropdownOptionItem } from './DropdownOption';

type ChildTextInputProps = TextInputProps;

export type DropdownProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  name: string;
  TextInputProps?: Omit<ChildTextInputProps, 'name' | 'onChange'>;
};

const Dropdown = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: DropdownProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { TextInputProps, name, ...rest } = props;
  return (
    <StyledAutocompleteWrapper>
      <Autocomplete
        {...rest}
        clearIcon={null}
        PaperComponent={StyledPaper}
        renderOption={(props, option, state) => {
          return (
            <DropdownOption
              {...props}
              option={option as DropdownOptionItem}
              selected={state.selected}
            />
          );
        }}
        renderInput={(params) => (
          <BaseInput
            ref={params.InputProps.ref}
            name={name}
            {...params}
            {...TextInputProps}
            inputProps={{
              ...params.inputProps, // spread render input props first
              ...(TextInputProps?.inputProps || {}),
            }}
            sx={{
              ...TextInputProps?.sx,
            }}
            InputProps={{
              ...params.InputProps, // spread render Input props first
              ...(TextInputProps?.InputProps || {}),
              endAdornment: (
                <OpenDropdown
                  sx={{
                    width: 10,
                    // don't intercept the click event
                    // not to interfere with the dropdown opening
                    pointerEvents: 'none',
                    // there's no "open" prop in the current scope
                    // the only way to know if the dropdown is open is by checking the inputProps['aria-expanded']
                    transform: params.inputProps['aria-expanded']
                      ? 'rotate(180deg)'
                      : 'none',
                    opacity: params.disabled ? 0.5 : 1,
                  }}
                />
              ),
            }}
          />
        )}
      />
    </StyledAutocompleteWrapper>
  );
};

// wrapper for popup component which will be rendered in the portal
// outside of the Autocomplete component
const StyledPaper = styled(Paper)(({ theme: _theme }) => ({
  // when the option labels are too small, we need to set a minimum width
  minWidth: 220,

  // options list wrapper
  '& .MuiAutocomplete-listbox': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StyledAutocompleteWrapper = styled(Box)(({ theme }) => ({
  // to avoid labels taking full width, and instead to take the required minimum
  width: 'fit-content',

  // label - description, extraDescription, etc
  '& .MuiFormLabel-root': {
    whiteSpace: 'initial', // to allow soft wrap in small screens
  },
  '& .MuiInputBase-root': {
    paddingRight: '14px !important', // this is to avoid the padding added by the clear icon
    cursor: 'pointer',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    cursor: 'initial',
  },
  '& .MuiAutocomplete-option': {
    padding: '8px 16px',
    ...theme.typography['Body M'],
  },
}));

export default Dropdown;

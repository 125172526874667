import { useMutation, useQuery } from '@apollo/client';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';
import {
  Departure,
  ItineraryWithPromotionalDiscountsDocument,
  MultiCurrencyAmountsInput,
  Scalars,
  UpdatePromotionalDiscountsDocument,
  UserRole,
} from '@flashpack/graphql';
import { FinanceEntity } from '@src/shared/finance/utils';
import Table, { TableData, TableRowContextOption } from '@src/shared/table/Table';
import {
  departuresToTableRows,
  notLockedMultiCurrencyHeaders,
  pasteFromAbove,
  showTableOpenMenuButton,
  lockPricesAndDiscountsFactory,
} from '@src/shared/table/utils';
import { useToast } from '@src/shared/toast/useToast';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { ReactNode, useMemo, useState } from 'react';
import { isRowComplete, isRowPartiallyComplete } from '../util';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { BulkUpdatePromotionalDiscountsModal } from './BulkUpdatePromotionalDiscountModal';

const defaultDepartures: Departure[] = [];

const lockedPricesMessage =
  'The promotional discounts table is read-only because you do not have permission to edit.';

export const PromotionalDiscountsTable = ({
  renderCanton,
}: {
  renderCanton: (hasPendingChanges: boolean) => ReactNode;
}) => {
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState<boolean>(false);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);
  const { error: errorToast, success: successToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const { itineraryId } = useRequiredParams(['itineraryId']);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const itinerary = useQuery(ItineraryWithPromotionalDiscountsDocument, {
    variables: {
      id: itineraryId,
    },
  });

  const { currentUser } = useAuthorization();
  const userIsSuperuser = currentUser?.role === UserRole.Superuser;
  const [updatePromotionalDiscounts] = useMutation(UpdatePromotionalDiscountsDocument);

  const rowOptions: TableRowContextOption[] = [pasteFromAbove];

  const departures = itinerary.data?.itinerary?.departures ?? defaultDepartures;
  // Fetch departure promotional discounts.
  const pricingData: TableData = useMemo(() => {
    return {
      headers: notLockedMultiCurrencyHeaders,
      rows: departuresToTableRows(
        departures,
        lockPricesAndDiscountsFactory(currentUser?.role),
      ),
    };
  }, [departures, currentUser]);

  const headerKeys = pricingData.headers.map((x) => x.name);

  const handleSubmitDiscountAmounts = async (formValues: TableData) => {
    if (formValues.rows.some((row) => isRowPartiallyComplete(headerKeys, row.values))) {
      errorToast('Partially complete rows are not allowed');
      return;
    }

    setIsSubmitting(true);
    successToast('Promotional discounts updated successfully');
    // TODO: Uncomment once mutation is implemented
    await safeMutation(
      updatePromotionalDiscounts({
        variables: {
          input: formValues.rows
            .filter((row) => isRowComplete(headerKeys, row.values) && !row.locked)
            .map((row) => {
              return {
                departureId: row.name,
                amounts: row.values as MultiCurrencyAmountsInput,
              };
            }),
        },
        refetchQueries: [ItineraryWithPromotionalDiscountsDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          successToast('Promotional discounts updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update promotional discounts');
        },
      },
    );
  };

  // Render table modelling.
  return (
    <>
      {!userIsSuperuser && (
        <WarningMessage message={lockedPricesMessage} iconType="LOCKED" />
      )}
      <BulkUpdatePromotionalDiscountsModal
        open={openBulkUpdateModal}
        onClose={() => setOpenBulkUpdateModal(false)}
        selectedDepartureIds={selectedDepartureIds}
      />
      <Table
        initialData={pricingData}
        handleSubmit={handleSubmitDiscountAmounts}
        rowOptions={rowOptions}
        editable={true}
        isLoading={isSubmitting || itinerary.loading}
        isRequired={false}
        renderCanton={renderCanton}
        showOpenMenuButton={showTableOpenMenuButton}
        submitLabel={'Save Discounts'}
        tableFormId="early-bird-discounts-table"
        entity={FinanceEntity.PROMOTIONAL_DISCOUNT}
        selectedRowIds={selectedDepartureIds}
        setSelectedRowIds={setSelectedDepartureIds}
        setOpenBulkUpdateModal={setOpenBulkUpdateModal}
      />
    </>
  );
};

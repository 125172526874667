import { Container, Drawer, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  ItineraryDeparturesDocument,
  AdventureDepartureDatesDocument,
  AddDeparturesToItineraryDocument,
  ItineraryWithTimelineDurationsDocument,
} from '@flashpack/graphql';
import { useTheme, styled } from '@mui/material/styles';
import {
  ProposeDeparturesForm,
  ProposeDepartureFormValues,
} from './ProposeDeparturesForm';
import { formatISO } from 'date-fns';
import { useToast } from '@src/shared/toast/useToast';
import { ApolloError, useMutation, useQuery } from '@apollo/client';

interface PropTypes {
  itineraryId: string;
  adventureId: string;
  adventureCode: string;
  itineraryDescription: string;
  onClose: () => void;
  open: boolean;
}

export const ProposeDeparturesDrawer: FC<PropTypes> = ({
  itineraryId,
  onClose,
  adventureCode,
  adventureId,
  itineraryDescription,
  open,
}) => {
  const [addDeparturesToItinerary] = useMutation(AddDeparturesToItineraryDocument, {
    refetchQueries: [ItineraryDeparturesDocument, AdventureDepartureDatesDocument],
  });

  const { data } = useQuery(AdventureDepartureDatesDocument, {
    variables: { adventureId },
  });
  const { data: itineraryData } = useQuery(ItineraryWithTimelineDurationsDocument, {
    variables: {
      id: itineraryId,
    },
  });

  const theme = useTheme();
  const { success, error: errorToast } = useToast();

  if (!itineraryData) {
    return null;
  }

  const onProposeDepartures = async (values: ProposeDepartureFormValues) => {
    const departures = values.departureDates.map((date) => ({
      date: formatISO(date, { representation: 'date' }),
      roomReleaseDays: values.roomRelease,
    }));

    try {
      await addDeparturesToItinerary({
        variables: {
          input: {
            itineraryId,
            departures,
            privateGroup: values.privateGroup,
          },
        },
      });
      success('Departures proposed');
    } catch (e) {
      const error = e as ApolloError;
      errorToast(error.message || 'Error occurred, departures were not created');
    } finally {
      onClose();
    }
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 1200,
          padding: theme.spacing(2),
        },
      }}
    >
      <Container sx={{ p: 5 }}>
        <Stack spacing={1} mb={3}>
          <Typography variant="subHeader" align="center">
            Propose departures
          </Typography>
          <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
            <DepartureMeta sx={{ textTransform: 'uppercase' }} variant="bodySingle">
              {adventureCode}
            </DepartureMeta>
            <Circle />
            <DepartureMeta variant="bodySingle">{itineraryDescription}</DepartureMeta>
          </Stack>
        </Stack>
        <ProposeDeparturesForm
          onSubmit={onProposeDepartures}
          onCancel={onClose}
          existingDepartures={
            data?.adventure.itineraries.map((i) => i.departures).flat() ?? []
          }
          itineraryDurationDays={
            itineraryData.itinerary.timeline.durationDays > 0
              ? itineraryData.itinerary.timeline.durationDays - 1
              : 0
          }
          itineraryLongestAccommodationDurationDays={
            itineraryData.itinerary.timeline.longestAccommodationDurationDays
          }
        />
      </Container>
    </Drawer>
  );
};

const DepartureMeta = styled(Typography)(({ theme }) => ({
  color: theme.palette.principal.grey70,
}));

const Circle = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  width: '10px',
  height: '10px',
  backgroundColor: theme.palette.principal.grey50,
}));

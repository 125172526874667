import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import MUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled, useTheme } from '@mui/material/styles';
import { MemoizedTableBodyCell } from './TableBodyCell';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Box, Stack } from '@mui/system';
import { FormState } from 'final-form';
import isEqual from 'lodash.isequal';
import { UpdateAmountsToolbar } from '@src/shared/finance/UpdateAmountsToolbar';
import { MoreVert } from '@mui/icons-material';
import { TableRowContextMenu } from './TableRowContextMenu';
import { FinanceEntity } from '../finance/utils';
import { TableRowLockedIcon } from './TableRowLockedIcon';
import { TableDialogBox } from './TableDialogBox';
import { useBlocker } from 'react-router-dom';
import {
  ConfirmationDialog,
  confirmationDialogLabels,
} from '@src/design-system/dialogs/ConfirmationDialog';
import { DepartureStatus, Scalars } from '@flashpack/graphql';
import { StatusIcon } from './StatusIcon';
import { Checkbox } from 'design-system';
import { TableToolbar } from './TableToolbar';

export type TableHeader = {
  name: string;
  label: string;
  hint?: string;
  locked: boolean;
};

export type TableRow = {
  name: string;
  label: string;
  status?: DepartureStatus;
  values: Record<string, number | null>;
  locked: boolean;
  meta?: unknown;
};

type LockIconSpecifications = {
  isClickable: boolean;
  hasToolTip: boolean;
  toolTipTextFunction?: (row: TableRow) => string;
};

type DialogBoxSpecifications = {
  title: string;
  dialogBoxTextFunction: (row: TableRow) => string;
};

export type TableData = {
  headers: TableHeader[];
  rows: TableRow[];
};

export type TableRowContextOption = {
  key: string;
  label: string;
  onClick: (
    targetRowIndex: number,
    table: TableData,
    setFormValues: Dispatch<SetStateAction<TableData>>,
  ) => void;
  icon?: ReactNode;
};

interface PropTypes {
  handleSubmit: (values: TableData) => Promise<void>;
  handleRowChanged?: (row: TableRow) => TableRow;
  initialData: TableData;
  rowOptions?: TableRowContextOption[];
  showOpenMenuButton?: (
    row: TableRow,
    rowIndex: number,
    rowOptions: TableRowContextOption[],
  ) => boolean;
  isLoading: boolean;
  isRequired: boolean;
  editable: boolean;
  tableFormId: string;
  tableWidth?: string;
  submitLabel: string;
  renderCanton?: (hasPendingChanges: boolean) => ReactNode;
  entity: FinanceEntity;
  nonTableScreenHeight?: number;
  cellWidth?: number;
  lockIconSpecifications?: LockIconSpecifications;
  dialogBoxSpecifications?: DialogBoxSpecifications;
  selectedRowIds?: Array<Scalars['UUID']>;
  setSelectedRowIds?: (value: SetStateAction<string[]>) => void;
  setOpenBulkUpdateModal?: (value: SetStateAction<boolean>) => void;
}

// TODO: This component is getting too big. At some point, we should split it into smaller components
export const Table: FC<PropTypes> = ({
  handleSubmit,
  handleRowChanged,
  rowOptions,
  showOpenMenuButton,
  initialData,
  isLoading = false,
  isRequired = true,
  editable,
  tableWidth = 'unset',
  tableFormId,
  submitLabel,
  renderCanton,
  entity,
  nonTableScreenHeight = 400,
  cellWidth = 80,
  lockIconSpecifications,
  dialogBoxSpecifications,
  selectedRowIds,
  setSelectedRowIds,
  setOpenBulkUpdateModal,
}) => {
  const rowsSelectable =
    !!setSelectedRowIds && !!selectedRowIds && !!setOpenBulkUpdateModal;
  const theme = useTheme();

  const [selectedAll, setSelectedAll] = useState(false);
  const allSelectableRowIds = initialData.rows
    .filter((row) => !row.locked)
    .map((row) => row.name);

  const [formValues, setFormValues] = useState<TableData>(initialData);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogBoxText, setDialogBoxText] = useState<string>('');

  const tableCellClickHandler = (row: TableRow) => {
    let bodyText = '';
    if (dialogBoxSpecifications) {
      bodyText = dialogBoxSpecifications.dialogBoxTextFunction(row);
    }
    return () => {
      setDialogBoxText(bodyText);
      setIsDialogOpen(true);
    };
  };

  const [rowMenuAnchor, setRowMenuAnchor] = useState<null | HTMLElement>(null);
  const [rowMenuTargetRowIndex, setRowMenuTargetRowIndex] = useState<null | number>(null);
  const isRowMenuOpen = Boolean(rowMenuAnchor);

  const handleRowMenuAnchorClose = () => {
    setRowMenuAnchor(null);
  };

  const showOpenMenuButtonOrHideAll = (
    row: TableRow,
    rowIndex: number,
    rowOptions: TableRowContextOption[],
  ) => {
    if (showOpenMenuButton) {
      return showOpenMenuButton(row, rowIndex, rowOptions);
    }
    return false;
  };

  const handleRowMenuAction = (
    action: (
      targetRowIndex: number,
      table: TableData,
      setFormValues: Dispatch<SetStateAction<TableData>>,
    ) => void,
  ) => {
    if (!rowMenuTargetRowIndex) {
      return;
    }
    action(rowMenuTargetRowIndex, formValues, setFormValues);
  };

  useEffect(() => {
    setFormValues(initialData);
  }, [initialData]);

  const changesCount =
    formValues.rows.length > 0
      ? initialData.rows.reduce((count, row, index) => {
          initialData.headers.forEach((header) => {
            if (row.values[header.name] != formValues.rows[index].values[header.name]) {
              count++;
            }
          });
          return count;
        }, 0)
      : 0;

  const blocker = useBlocker(changesCount > 0);

  const onFormStateChange = (formState: FormState<TableData, Partial<TableData>>) => {
    // we avoid updating the state if the values are unchanged
    if (isEqual(formState.values, formValues)) {
      return;
    }

    setFormValues({
      ...formState.values,
      rows: formState.values.rows.map((row) => {
        if (handleRowChanged) {
          return handleRowChanged(row);
        }

        return {
          ...row,
          values: row.values ?? {},
        };
      }),
    });
  };

  const toggleSelectedRow = (rowId: Scalars['UUID']) => {
    if (!rowsSelectable) {
      return;
    }
    if (selectedRowIds.includes(rowId)) {
      setSelectedRowIds((ids) => ids.filter((id) => id !== rowId));
      if (selectedAll) {
        setSelectedAll(false);
      }
    } else {
      if (selectedRowIds.length === allSelectableRowIds.length - 1) {
        setSelectedAll(true);
      }
      setSelectedRowIds((ids) => [...ids, rowId]);
    }
  };

  const toggleSelectAll = () => {
    if (!rowsSelectable) {
      return;
    }
    if (selectedAll) {
      setSelectedRowIds([]);
    } else {
      setSelectedRowIds(allSelectableRowIds);
    }
    setSelectedAll(!selectedAll);
  };

  const hasSelectableRows = initialData.rows.some((row) => !row.locked);

  return (
    <TableContainerBox>
      {rowsSelectable && (
        <TableToolbar
          selectedRowIds={selectedRowIds}
          onClick={() => {
            setOpenBulkUpdateModal(true);
          }}
          deselectAll={() => {
            setSelectedRowIds([]);
            setSelectedAll(false);
          }}
        />
      )}
      <TableContainer
        component={TablePaper}
        entity={entity}
        nonTableScreenHeight={nonTableScreenHeight}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={formValues}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={(tableData) => void handleSubmit(tableData)} id={tableFormId}>
              <MUITable
                sx={{
                  width: tableWidth,
                  borderCollapse: 'separate',
                  borderSpacing: '0px 8px',
                  marginTop: '-8px',
                }}
                size="small"
                stickyHeader
              >
                <TableHead>
                  <TableHeaderRow>
                    <TableCell
                      sx={{
                        borderBottom: '0px',
                        backgroundColor: theme.palette.principal.grey30,
                        position: 'sticky',
                        zIndex: 3,
                        left: 0,
                      }}
                    >
                      <Stack direction="row" alignItems="center">
                        {rowsSelectable && (
                          <Tooltip title="Select all">
                            <Box>
                              <Checkbox
                                onChange={toggleSelectAll}
                                checked={selectedAll}
                                disabled={!hasSelectableRows}
                              />
                            </Box>
                          </Tooltip>
                        )}
                        {renderCanton && renderCanton(changesCount > 0)}
                      </Stack>
                      {renderCanton && (
                        <TableHeaderDivider
                          sx={{ width: rowsSelectable ? '215px' : '165px' }}
                        />
                      )}
                    </TableCell>
                    {initialData.headers.map((header, index) => (
                      <TableHeaderCell key={index} cellWidth={cellWidth}>
                        <HeaderBox sx={{ flexDirection: 'column' }}>
                          <Typography variant="bodyPara">{header.label}</Typography>
                          {header.hint && (
                            <Typography variant="micro">{header.hint}</Typography>
                          )}
                        </HeaderBox>
                        <TableHeaderDivider />
                      </TableHeaderCell>
                    ))}
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="rows">
                    {({ fields }) =>
                      fields.map((row, rowIndex) => (
                        <TableBodyRow key={rowIndex}>
                          <TableRowNameCell key={rowIndex}>
                            <Box display="flex" sx={{ width: '100%' }}>
                              {rowsSelectable && (
                                <Checkbox
                                  onChange={() =>
                                    toggleSelectedRow(initialData.rows[rowIndex].name)
                                  }
                                  checked={selectedRowIds.includes(
                                    initialData.rows[rowIndex].name,
                                  )}
                                  disabled={
                                    !editable || initialData.rows[rowIndex].locked
                                  }
                                  data-testid={`table-row-checkbox-${rowIndex}`}
                                />
                              )}
                              <TableRowNameText
                                variant="bodyPara"
                                displayStatus={!!initialData.rows[rowIndex].status}
                              >
                                {initialData.rows[rowIndex].status && (
                                  <StatusIcon
                                    status={
                                      initialData.rows[rowIndex].status as DepartureStatus
                                    }
                                  />
                                )}
                                <Box
                                  width="100%"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <span>{initialData.rows[rowIndex].label}</span>
                                  {initialData.rows[rowIndex].locked &&
                                    lockIconSpecifications && (
                                      <TableRowLockedIcon
                                        hasToolTip={lockIconSpecifications.hasToolTip}
                                        toolTipText={
                                          lockIconSpecifications.toolTipTextFunction
                                            ? lockIconSpecifications.toolTipTextFunction(
                                                initialData.rows[rowIndex],
                                              )
                                            : ''
                                        }
                                      />
                                    )}
                                  {showOpenMenuButtonOrHideAll(
                                    initialData.rows[rowIndex],
                                    rowIndex,
                                    rowOptions ?? [],
                                  ) && (
                                    <span>
                                      <RowContextButton
                                        onClick={(
                                          event: React.MouseEvent<HTMLElement>,
                                        ) => {
                                          setRowMenuAnchor(event.currentTarget);
                                          setRowMenuTargetRowIndex(rowIndex);
                                        }}
                                        data-testid="table-row-context-menu-button"
                                      >
                                        <MoreVert
                                          htmlColor={theme.palette.principal.grey50}
                                        />
                                      </RowContextButton>
                                    </span>
                                  )}
                                </Box>
                              </TableRowNameText>
                            </Box>
                          </TableRowNameCell>
                          {initialData.headers.map((header, headerIndex) => (
                            <MemoizedTableBodyCell
                              key={headerIndex}
                              name={`${row}.values.${header.name}`}
                              value={formValues.rows[rowIndex].values[header.name]}
                              loading={isLoading}
                              editable={
                                editable &&
                                !header.locked &&
                                !initialData.rows[rowIndex].locked
                              }
                              required={isRequired}
                              isLast={headerIndex === initialData.headers.length - 1}
                              tooltipValue={`${initialData.rows[rowIndex].label}; ${header.label}`}
                              clickHandler={tableCellClickHandler(
                                initialData.rows[rowIndex],
                              )}
                            />
                          ))}
                        </TableBodyRow>
                      ))
                    }
                  </FieldArray>
                </TableBody>
              </MUITable>
              <FormSpy onChange={onFormStateChange} />
            </form>
          )}
        </Form>
        <UpdateAmountsToolbar
          visible={changesCount > 0}
          changesCount={changesCount}
          formId={tableFormId}
          handleCancelUpdate={() => {
            setFormValues(initialData);
          }}
          submitLabel={submitLabel}
          entity={entity}
        />
        {rowOptions && (
          <TableRowContextMenu
            isOpen={isRowMenuOpen}
            options={rowOptions}
            anchorEl={rowMenuAnchor}
            handleAction={handleRowMenuAction}
            handleClose={handleRowMenuAnchorClose}
          />
        )}
      </TableContainer>
      {dialogBoxSpecifications && (
        <TableDialogBox
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          title={dialogBoxSpecifications.title}
          body={dialogBoxText}
        />
      )}
      {blocker ? (
        <ConfirmationDialog
          title={confirmationDialogLabels.title}
          cancelLabel={confirmationDialogLabels.cancelLabel}
          confirmLabel={confirmationDialogLabels.confirmLabel}
          invertedCancel
          onCancel={() => {
            blocker.proceed?.();
          }}
          onConfirm={() => {
            blocker.reset?.();
          }}
          open={blocker.state == 'blocked'}
        >
          <Typography sx={{ mb: 5 }} variant="bodyPara">
            {confirmationDialogLabels.content}
          </Typography>
        </ConfirmationDialog>
      ) : null}
    </TableContainerBox>
  );
};

interface TableHeaderCellProps {
  cellWidth: number;
}

export const TableHeaderCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'cellWidth',
})<TableHeaderCellProps>(({ theme, cellWidth }) => ({
  padding: 0,
  textAlign: 'center',
  backgroundColor: theme.palette.principal.grey30,
  borderBottom: '0px',
  width: cellWidth,
}));

export const HeaderBox = styled(Stack)(({ theme }) => ({
  display: 'flex',
  height: theme.spacing(6),
  backgroundColor: theme.palette.principal.white,
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TableHeaderDivider = styled(Box)(({ theme }) => ({
  borderBottom: '0px',
  backgroundColor: theme.palette.principal.grey50,
  height: '1px',
  padding: 0,
  marginTop: theme.spacing(1),
}));

const TableRowNameCell = styled(TableCell)(({ theme }) => ({
  width: '200px',
  textAlign: 'center',
  paddingRight: theme.spacing(2),
  paddingTop: '0px',
  paddingBottom: '0px',
  backgroundColor: theme.palette.principal.grey30,
  borderBottom: '0px',
  position: 'sticky',
  left: 0,
  zIndex: 2,
}));

interface TableRowNameTextProps {
  displayStatus?: boolean;
}

const TableRowNameText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'displayStatus',
})<TableRowNameTextProps>(({ theme, displayStatus }) => ({
  backgroundColor: theme.palette.principal.white,
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  paddingLeft: displayStatus ? theme.spacing(0.5) : theme.spacing(2),
  height: '40px',
}));

export const TableHeaderRow = styled(TableRow)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

export const TableBodyRow = styled(TableRow)(({}) => ({
  '& .MuiTableRow-root': {
    height: '40px',
  },
}));

interface TablePaperProps {
  entity: FinanceEntity;
  nonTableScreenHeight: number;
}

const TablePaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'entity' && prop !== 'nonTableScreenHeight',
})<TablePaperProps>(({ theme, entity, nonTableScreenHeight }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.principal.grey30,
  paddingRight: theme.spacing(1),
  height:
    entity === FinanceEntity.DEPOSIT
      ? undefined
      : `calc(100vh - ${nonTableScreenHeight}px)`,
  overflowY: 'scroll',
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
    width: '8px',
    height: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    backgroundColor: theme.palette.principal.grey50,
    '&:hover': {
      backgroundColor: theme.palette.principal.grey70,
    },
  },
}));

export const TableContainerBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(0.5),
  backgroundColor: theme.palette.principal.grey30,
}));

const RowContextButton = styled(IconButton)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'none',
  padding: theme.spacing(0.7),
}));

export default Table;

import { FC, useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Checkbox, Divider } from 'design-system';
import { countryFromCode } from 'src/shared/countries/countryUtils';
import {
  AdventuresDocument,
  UpdateFavoriteAdventuresDocument,
  ArchiveAdventureDocument,
} from '@flashpack/graphql';
import { RoutePath } from 'src/shared/routePath';
import { useMutation, useQuery } from '@apollo/client';

import {
  adventureTypeTitle,
  adventureTypeBackgroundColor,
} from '@src/shared/adventure/util';
import { useToast } from '@src/shared/toast/useToast';

import {
  Bulletin,
  BulletinList,
  SkeletonBulletinList,
} from '@src/design-system/bulletin';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { checkboxType } from '@src/shared/checkbox/checkbox';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { FORM_ERROR } from 'final-form';
import { FormControlLabel } from '@src/shared/FormControlLabel';
import { Box } from '@mui/system';
import { Environment, environmentIs } from '@src/app/environment';
import { adventureIdsForBetaReleaseDepartureSignOffs } from '../common/utils';

interface PropTypes {
  searchFilter?: string;
}

type PendingArchiveAdventure = {
  id: string;
  archived: boolean;
};
export const AdventureList: FC<PropTypes> = ({ searchFilter }) => {
  const { data, error, loading } = useQuery(AdventuresDocument);
  const { safeMutation } = useSafeMutation();
  const { success, error: errorToast } = useToast();
  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);
  const [archiveAdventure, { loading: isArchiving }] = useMutation(
    ArchiveAdventureDocument,
  );
  const [pendingArchiveAdventure, setPendingArchiveAdventure] =
    useState<PendingArchiveAdventure | null>(null);
  const archiveDialogTitle = `${
    pendingArchiveAdventure?.archived ? 'Unarchive' : 'Archive'
  }`;
  const [isShowingArchivedAdventures, setIsShowingArchivedAdventures] =
    useState<boolean>(false);
  const handleOpenArchiveDialog = (adventure: PendingArchiveAdventure) => {
    setArchiveDialogOpen(true);
    setPendingArchiveAdventure(adventure);
  };
  const archiveVersion = useCallback(async () => {
    if (!pendingArchiveAdventure) {
      return;
    }
    const { id: adventureId, archived } = pendingArchiveAdventure;
    const verb = archived ? 'unarchive' : 'archive';
    try {
      await safeMutation(
        archiveAdventure({
          variables: {
            input: { id: adventureId, archived: !archived },
          },
        }),
        {
          onSuccess: () => {
            success(`Adventure was ${verb}d.`);
          },
          onServerValidationError: (error) => {
            throw error;
          },
          onUnexpectedError: () =>
            errorToast("This adventure couldn't have been archived at this time"),
        },
      );
    } catch (error) {
      return {
        [FORM_ERROR]: error,
      };
    }
    setArchiveDialogOpen(false);
    setPendingArchiveAdventure(null);
  }, [archiveAdventure, errorToast, safeMutation, pendingArchiveAdventure, success]);

  const [updateFavoriteAdventuresMutation] = useMutation(
    UpdateFavoriteAdventuresDocument,
  );

  if (loading) {
    return <SkeletonBulletinList count={3} />;
  }

  if (error) {
    return (
      <Typography marginBottom={8}>
        There was a problem loading the adventures. Please contact{' '}
        <a href="https://flashpack20.slack.com/archives/C03PWQLA6RE">#product-team-1</a>
      </Typography>
    );
  }

  if (!data || data?.adventures.length == 0) {
    return <Typography marginBottom={8}>You don&apos;t have any adventures.</Typography>;
  }

  const adventuresWithCountries = data.adventures.map(({ code, ...rest }) => {
    const countries = code.split('_').slice(0, -1);
    const countryNames = countries.map(
      (countryCode) => countryFromCode(countryCode)?.name,
    );
    return { code, countryNames, ...rest };
  });

  const onSubmit = (id: string, favorite: boolean) => async () => {
    await safeMutation(
      updateFavoriteAdventuresMutation({
        variables: {
          input: {
            id: id,
            favorite: !favorite,
          },
        },
      }),
      {
        onSuccess: () => success('My Adventures updated'),
        onError: () => errorToast('Failed to update My Adventures'),
      },
    );
  };

  let visibleAdventures = searchFilter
    ? adventuresWithCountries.filter(
        (adventure) =>
          adventure.bookingEngineTourCode
            .toLowerCase()
            .includes(searchFilter.toLowerCase()) ||
          adventure.title?.toLocaleLowerCase().includes(searchFilter.toLowerCase()) ||
          adventure.countryNames.some((countryName) =>
            countryName?.toLowerCase().includes(searchFilter.toLowerCase()),
          ),
      )
    : adventuresWithCountries;
  visibleAdventures = isShowingArchivedAdventures
    ? visibleAdventures
    : visibleAdventures.filter((adventure) => !adventure.archived);

  const favoriteVisibleAdventures = visibleAdventures.filter(
    (adventure) => adventure.favorite,
  );
  const unfavoriteVisibleAdventures = visibleAdventures.filter(
    (adventure) => !adventure.favorite,
  );

  if (visibleAdventures.length == 0 && favoriteVisibleAdventures.length == 0) {
    return (
      <Typography marginBottom={8}>
        You don&apos;t have any adventures matching this search.
      </Typography>
    );
  }

  const bulletinList = (
    adventuresList: typeof adventuresWithCountries,
    checkboxType: checkboxType,
    favorite: boolean,
  ) => (
    <BulletinList>
      {adventuresList.map(
        ({ bookingEngineTourCode, id, title, type, countryNames, archived }) => {
          return (
            <Bulletin
              key={id}
              // TODO: Leave only the actions link when full release
              href={
                !environmentIs(Environment.Production) ||
                adventureIdsForBetaReleaseDepartureSignOffs.includes(id)
                  ? RoutePath.ADVENTURE_ACTIONS.generatePath(id)
                  : RoutePath.ADVENTURE_ITINERARIES.generatePath(id)
              }
              data-testid="adventure-link"
              title={title ? title : countryNames.join(' - ')}
              subtitle={bookingEngineTourCode}
              checkbox={checkboxType}
              onSelect={onSubmit(id, favorite)}
              archived={archived}
              onArchiveClick={() => handleOpenArchiveDialog({ id, archived })}
              disabled={isArchiving}
              sx={{ px: 2, py: 1 }}
              chips={type.map((type) => ({
                label: adventureTypeTitle(type),
                sx: {
                  background: adventureTypeBackgroundColor(type),
                },
              }))}
            />
          );
        },
      )}
    </BulletinList>
  );
  return (
    <>
      <Stack direction="row" justifyContent="end" sx={{ mt: 2, mx: 0 }}>
        <FormControlLabel
          label="Include Archived"
          checked={isShowingArchivedAdventures}
          control={
            <Checkbox
              data-testid="archive-adventure-checkbox"
              checked={isShowingArchivedAdventures}
              onChange={() =>
                setIsShowingArchivedAdventures((currentState) => !currentState)
              }
            />
          }
        />
      </Stack>
      {favoriteVisibleAdventures.length > 0 && (
        <>
          <Typography mb={2} variant="bodySingle">
            My Adventures
          </Typography>
          {bulletinList(favoriteVisibleAdventures, checkboxType.favorite, true)}
          <Divider
            style={{
              borderWidth: 0.5,
            }}
          ></Divider>
        </>
      )}
      <Box mt={2}>
        {bulletinList(unfavoriteVisibleAdventures, checkboxType.unfavorite, false)}
      </Box>
      <ConfirmationDialog
        title={archiveDialogTitle}
        cancelLabel="Cancel"
        confirmLabel={pendingArchiveAdventure?.archived ? 'Unarchive' : 'Archive'}
        open={archiveDialogOpen}
        onCancel={() => setArchiveDialogOpen(false)}
        onConfirm={() => void archiveVersion()}
      />
    </>
  );
};

import { ApolloError, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { styled, Typography, Stack, Button, Box } from '@mui/material';
import { AutocompleteField } from '@src/design-system/forms/autocomplete/AutocompleteField';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { Validator } from '@src/design-system/forms/validators';
import { GenericError, CheckboxFormField, composeValidators } from 'design-system';
import { AdventureRegion, CreateAdventureDocument } from '@flashpack/graphql';
import { regionOptions, countryOptions } from '@src/shared/countries/countryUtils';
import { hasServerValidationErrors } from '@src/shared/errorUtils';
import { RoutePath } from '@src/shared/routePath';
import { useToast } from '@src/shared/toast/useToast';
import { useRouting } from '@src/shared/useRouting';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import { FC, useCallback } from 'react';
import { Field, Form } from 'react-final-form';

export const AddAdventurePage = () => {
  const [createAdventure] = useMutation(CreateAdventureDocument);
  const { navigate } = useRouting();
  const { success, error: errorToast } = useToast();

  const onSubmit = useCallback(
    async (values: CreateAdventureFormType) => {
      try {
        const { data } = await createAdventure({
          variables: {
            input: values,
          },
        });

        const itineraries = data?.createAdventure.itineraries ?? [];
        if (!itineraries.length) {
          throw new Error('Could not create a default itinerary for this adventure');
        }

        navigate(
          RoutePath.ITINERARY_TIMELINE.generatePath(
            data?.createAdventure.id || '',
            itineraries[0].id,
          ),
        );
        success('Adventure created');
      } catch (error) {
        if (error instanceof ApolloError) {
          errorToast(error.message);
        } else {
          return {
            [FORM_ERROR]: error,
          };
        }
      }
    },
    [createAdventure, navigate, success, errorToast],
  );

  return (
    <Container>
      <Heading>Create an Adventure</Heading>
      <AdventureForm onSubmit={onSubmit} initialValues={{}} />
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 'auto'),
  maxWidth: theme.breakpoints.values.md,
}));

const Heading = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(5),
}));
Heading.defaultProps = {
  variant: 'bodySingle',
};

type CreateAdventureFormType = {
  title: string;
  countryCodes: string[];
  region: AdventureRegion;
  ATOL: boolean;
  bookingEngineTourCode: string;
};

type AdventureFormProps = {
  onSubmit: (values: CreateAdventureFormType) => Promise<unknown>;
  initialValues: CreateAdventureFormType | Record<string, never>;
};

const AdventureForm: FC<AdventureFormProps> = ({ onSubmit, initialValues }) => {
  const submitLabel = 'Create an adventure';
  return (
    <Form<CreateAdventureFormType>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, dirtySinceLastSubmit, submitError }) => {
        const apolloError = submitError as ApolloError;
        if (apolloError && !hasServerValidationErrors(apolloError)) {
          return <GenericError error={apolloError} />;
        }

        return (
          <form onSubmit={(v) => void handleSubmit(v)}>
            <Stack gap={2}>
              <Stack direction="row" flexDirection="row" gap={2} display="flex">
                <Box flex={1}>
                  <TextField
                    multiline
                    rows={1}
                    name="title"
                    label="Adventure Title"
                    fieldProps={{ validate: Validator.required }}
                  />
                </Box>
                <Box flex={1}>
                  <TextField
                    autoFocus
                    name="bookingEngineTourCode"
                    label="Adventure Code"
                    fieldProps={{
                      validate: composeValidators(
                        Validator.required,
                        Validator.bookingEngineTourCodeValidator,
                      ),
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" gap={2} display="flex">
                <Box flex={1}>
                  <AutocompleteField<{ value: string }, true, true, false>
                    name="countryCodes"
                    label="Countries"
                    options={countryOptions}
                    getOptionValue={(option) => option.value}
                    multiple
                    fullWidth
                    fieldProps={{ validate: Validator.required }}
                  />
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="bodySingle" color="primary" paddingY={2}>
                      ATOL:
                    </Typography>
                    <Field<boolean>
                      name="ATOL"
                      type="checkbox"
                      defaultValue={false}
                      component={CheckboxFormField}
                    />
                  </Box>
                </Box>
                <Box flex={1}>
                  <AutocompleteField<{ value: string }, false, true, false>
                    name="region"
                    label="Region"
                    options={regionOptions}
                    getOptionValue={(option) => option.value}
                    fieldProps={{ validate: Validator.required }}
                  />
                </Box>
              </Stack>

              {!dirtySinceLastSubmit && <ServerValidationErrors error={apolloError} />}

              <Actions>
                <LoadingButton variant="contained" type="submit" loading={submitting}>
                  {submitLabel}
                </LoadingButton>
                <Button href={RoutePath.ADVENTURES.value} variant="outlined">
                  Cancel
                </Button>
              </Actions>
            </Stack>
          </form>
        );
      }}
    />
  );
};

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'max-content',
  gap: theme.spacing(2),
  margin: theme.spacing(4, 'auto', 0),
  '& *': {
    width: '100%',
  },
}));

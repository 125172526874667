import { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Protected } from '@src/authentication/Protected';
import {
  DepartureFinancesQueryDocument,
  PassengerCostAmountsInput,
  Scalars,
  UserRole,
} from '@flashpack/graphql';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FinanceEntity } from '@src/shared/finance/utils';
import { BulkUpdatePricesModal } from '@src/itinerary/prices-page/BulkUpdatePricesModal';
import { BulkUpdateEarlyBirdDiscountsModal } from '@src/itinerary/prices-page/BulkUpdateEarlyBirdDiscountsModal';
import { flashPackTheme } from '@src/design-system/theme';
import { BulkUpdatePassengerCostsModal } from '@src/itinerary/costs-page/BulkUpdatePassengerCostsModal';
import {
  BulkUpdateExtrasCostsModal,
  BulkUpdateExtrasCostsModalFormType,
} from '@src/itinerary/costs-page/BulkUpdateExtrasCostsModal';
import {
  BulkUpdateMandatoryCostsModal,
  BulkUpdateMandatoryCostsModalFormType,
} from '@src/itinerary/costs-page/BulkUpdateMandatoryCostsModal';
import { BulkUpdatePromotionalDiscountsModal } from '@src/itinerary/prices-page/BulkUpdatePromotionalDiscountModal';

type PropsTypes = {
  departureId: Scalars['UUID'];
  itineraryId: Scalars['UUID'];
};

export const UpdateDepartureFinancesButton: FC<PropsTypes> = ({
  itineraryId,
  departureId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [openEditFinancesModal, setOpenEditFinancesModal] =
    useState<FinanceEntity | null>(null);

  const { data, loading } = useQuery(DepartureFinancesQueryDocument, {
    variables: { departureId, itineraryId },
  });

  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(e.target as HTMLElement);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = (modal: FinanceEntity) => {
    setOpenEditFinancesModal(modal);
    handleCloseMenu();
  };

  const handleModalClose = () => {
    setOpenEditFinancesModal(null);
  };

  return (
    <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
      <Tooltip title="Update Price, Early Bird Discount and Costs">
        <IconButton onClick={handleClick}>
          {loading ? (
            <CircularProgress
              sx={{ color: flashPackTheme.palette.principal.grey70 }}
              size="24px"
            />
          ) : (
            <AttachMoneyIcon />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="update-departure-finances-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
      >
        <Protected roles={[UserRole.Flashpack]}>
          <MenuItem onClick={() => handleModalOpen(FinanceEntity.PRICE)}>
            Update Trip price
          </MenuItem>
          <MenuItem onClick={() => handleModalOpen(FinanceEntity.EARLY_BIRD_DISCOUNT)}>
            Update Early Bird Discount
          </MenuItem>
          <MenuItem onClick={() => handleModalOpen(FinanceEntity.PROMOTIONAL_DISCOUNT)}>
            Update Promotional Discount
          </MenuItem>
        </Protected>
        <MenuItem onClick={() => handleModalOpen(FinanceEntity.PASSENGER_COST)}>
          Update Passenger Costs
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen(FinanceEntity.EXTRAS_COST)}>
          Update Extras Costs
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen(FinanceEntity.MANDATORY_COST)}>
          Update Mandatory Costs
        </MenuItem>
      </Menu>
      <Protected roles={[UserRole.Flashpack]}>
        <BulkUpdatePricesModal
          open={openEditFinancesModal === FinanceEntity.PRICE}
          onClose={handleModalClose}
          selectedDepartureIds={[departureId]}
          initialValues={data?.itinerary?.departureById.tripPrice?.amountUSD}
        />
        <BulkUpdateEarlyBirdDiscountsModal
          open={openEditFinancesModal === FinanceEntity.EARLY_BIRD_DISCOUNT}
          onClose={handleModalClose}
          selectedDepartureIds={[departureId]}
          initialValues={data?.itinerary?.departureById.earlyBirdDiscount}
        />
        <BulkUpdatePromotionalDiscountsModal
          open={openEditFinancesModal === FinanceEntity.PROMOTIONAL_DISCOUNT}
          onClose={handleModalClose}
          selectedDepartureIds={[departureId]}
          initialValues={data?.itinerary?.departureById.promotionalDiscount}
        />
      </Protected>
      <BulkUpdatePassengerCostsModal
        open={openEditFinancesModal === FinanceEntity.PASSENGER_COST}
        onClose={handleModalClose}
        selectedDepartureIds={[departureId]}
        costCurrency={data?.itinerary?.costCurrency}
        locked={data?.itinerary?.passengerCostsLocked}
        initialValues={
          data?.itinerary?.departureById.passengerCost as PassengerCostAmountsInput
        }
      />
      <BulkUpdateExtrasCostsModal
        open={openEditFinancesModal === FinanceEntity.EXTRAS_COST}
        onClose={handleModalClose}
        selectedDepartureIds={[departureId]}
        costCurrency={data?.itinerary?.costCurrency}
        locked={data?.itinerary?.extrasCostsLocked}
        initialValues={
          data?.itinerary?.departureById.extrasCost as BulkUpdateExtrasCostsModalFormType
        }
      />
      <BulkUpdateMandatoryCostsModal
        open={openEditFinancesModal === FinanceEntity.MANDATORY_COST}
        onClose={handleModalClose}
        selectedDepartureIds={[departureId]}
        costCurrency={data?.itinerary?.costCurrency}
        locked={data?.itinerary?.mandatoryCostsLocked}
        initialValues={
          data?.itinerary?.departureById
            .mandatoryCost as BulkUpdateMandatoryCostsModalFormType
        }
      />
    </Protected>
  );
};

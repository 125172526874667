import { FC, useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DeleteTrashCanIcon } from 'design-system';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useRouting } from '@src/shared/useRouting';
import { useMutation } from '@apollo/client';
import {
  AdventureDepartureDatesDocument,
  AdventureWithItinerariesAndDeparturesDocument,
  DeleteDeparturesDocument,
  ItineraryDeparturesDocument,
  Scalars,
} from '@flashpack/graphql';
import { DeleteDeparturesConfirmation } from '../DeleteDeparturesConfirmation';
import { useToast } from '@src/shared/toast/useToast';
import { pluralDepartures } from '../utils';

type DeleteDepartureCombinedButtonProps = {
  isWhite?: boolean;
  departureIds: Scalars['UUID'][];
};

type DeleteManyDepartureButtonProps = DeleteDepartureCombinedButtonProps & {
  isMultiple: true;
  deselectAll: () => void;
};

type DeleteOneDepartureButtonProps = DeleteDepartureCombinedButtonProps & {
  isMultiple: false;
};

type DeleteDepartureButtonProps =
  | DeleteManyDepartureButtonProps
  | DeleteOneDepartureButtonProps;

export const DeleteDepartureButton: FC<DeleteDepartureButtonProps> = (props) => {
  const { navigate } = useRouting();
  const { success, error } = useToast();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

  const [deleteDeparturesMutation, { loading }] = useMutation(DeleteDeparturesDocument, {
    refetchQueries: [ItineraryDeparturesDocument, AdventureDepartureDatesDocument],
  });

  const deleteDeparture = async () => {
    try {
      await deleteDeparturesMutation({
        variables: { departureIds: props.departureIds },
        refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
      });

      const successfulMutationMessage = `${pluralDepartures(
        props.departureIds.length,
      )} deleted`;

      setDeleteConfirmationOpen(false);
      success(successfulMutationMessage);

      if (props.isMultiple) {
        props.deselectAll();
      } else {
        navigate(-1);
      }
    } catch (err) {
      const failedMutationMessage = `Error deleting ${pluralDepartures(
        props.departureIds.length,
      )}`;

      error(failedMutationMessage);
    }
  };

  const trashCanIcon = props.isWhite ? (
    <DeleteOutlineIcon htmlColor="white" />
  ) : (
    <DeleteTrashCanIcon htmlColor={props.isWhite ? 'white' : ''} />
  );

  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => setDeleteConfirmationOpen(true)}
          data-testid="delete-selected-departures"
        >
          {loading ? (
            <CircularProgress
              sx={{ color: props.isWhite ? 'white' : 'black' }}
              size="24px"
            />
          ) : (
            trashCanIcon
          )}
        </IconButton>
      </Tooltip>
      <DeleteDeparturesConfirmation
        open={deleteConfirmationOpen}
        onConfirm={() => void deleteDeparture()}
        onCancel={() => setDeleteConfirmationOpen(false)}
      />
    </>
  );
};

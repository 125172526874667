import { Typography, useTheme } from '@mui/material';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { FC, useState } from 'react';
import { pluralDepartures } from '@src/departures/utils';
import { notLockedMultiCurrencyHeaders } from '@src/shared/table/utils';
import MinimalTable, { MinimalTableData } from '@src/shared/table/MinimalTable';
import {
  BulkUpdateEarlyBirdDiscountsDocument,
  DepartureFinancesQueryDocument,
  ItineraryWithEarlyBirdDiscountsDocument,
  MultiCurrencyAmountsInput,
  Scalars,
  UserRole,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useMutation } from '@apollo/client';
import { isRowComplete } from '../util';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  selectedDepartureIds: Array<Scalars['UUID']>;
  initialValues?: MultiCurrencyAmountsInput | null;
}

export const BulkUpdateEarlyBirdDiscountsModal: FC<PropTypes> = ({
  open,
  onClose,
  selectedDepartureIds,
  initialValues,
}) => {
  const theme = useTheme();
  const { currentUser } = useAuthorization();
  const title = `Update ${pluralDepartures(selectedDepartureIds.length)}?`;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { error: errorToast, success: successToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const [bulkUpdateEarlyBirdDiscounts] = useMutation(
    BulkUpdateEarlyBirdDiscountsDocument,
  );

  const handleUpdateEarlyBirdDiscounts = async (tableData: MinimalTableData) => {
    const headerKeys = tableData.headers.map((x) => x.name);
    if (!isRowComplete(headerKeys, tableData.rows[0].values)) {
      errorToast('Please fill out early bird discounts in all currencies to save.');
      return;
    }

    setIsSubmitting(true);
    await safeMutation(
      bulkUpdateEarlyBirdDiscounts({
        variables: {
          input: {
            departureIds: selectedDepartureIds,
            amounts: tableData.rows[0].values as MultiCurrencyAmountsInput,
          },
        },
        refetchQueries: [
          ItineraryWithEarlyBirdDiscountsDocument,
          DepartureFinancesQueryDocument,
        ],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          onClose();
          successToast('Early bird discounts updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update early bird discounts');
        },
      },
    );
  };

  const initialData: MinimalTableData = {
    headers: notLockedMultiCurrencyHeaders,
    rows: [
      {
        values: {
          amountUSD: initialValues?.amountUSD || null,
          amountCAD: initialValues?.amountCAD || null,
          amountGBP: initialValues?.amountGBP || null,
          amountEUR: initialValues?.amountEUR || null,
          amountAUD: initialValues?.amountAUD || null,
          amountNZD: initialValues?.amountNZD || null,
          amountAED: initialValues?.amountAED || null,
          amountHKD: initialValues?.amountHKD || null,
        },
      },
    ],
  };

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onClose}
      title={title}
      maxWidth={'md'}
      cancelLabel="Cancel"
      confirmLabel="Update"
      confirmLoading={isSubmitting}
      confirmFormId="bulk-early-bird-discounts-table"
      disableConfirmButton={currentUser?.role !== UserRole.Superuser}
    >
      <Typography variant="captionPara" color={theme.palette.principal.grey70}>
        Discounts must be entered individually for each currency.
      </Typography>
      <Typography variant="bodySingle">Early Bird Discounts</Typography>

      <MinimalTable
        initialData={initialData}
        handleSubmit={handleUpdateEarlyBirdDiscounts}
        tableFormId="bulk-early-bird-discounts-table"
        isLoading={isSubmitting}
        editable={currentUser?.role === UserRole.Superuser}
      />
    </ConfirmationDialog>
  );
};

import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Typography, WarningIcon } from '..';

export interface BaseInputProps
  extends Omit<TextFieldProps, 'name' | 'variant' | 'size'> {
  name: string;
  size?: 'small' | 'medium' | 'large' | 'fullWidth';
  description?: string;
  extraDescription?: string;
  testid?: string;
}

export const BaseInput: React.FC<BaseInputProps> = forwardRef<
  HTMLDivElement,
  BaseInputProps
>(
  (
    {
      label,
      description,
      helperText,
      extraDescription,
      error,
      testid,
      size = 'medium',
      ...props
    },
    ref,
  ) => {
    return (
      <Box sx={{ ...(size === 'fullWidth' ? { width: '100%' } : {}) }}>
        {label && (
          <Box
            sx={{
              marginBottom: 0.5,
              color: error ? 'system.red100' : 'principal.black',
              display: 'flex',
            }}
            id={`label-${props.name}`}
          >
            {error && (
              <WarningIcon sx={{ height: '12px', marginY: 'auto', marginLeft: -0.5 }} />
            )}

            <Typography variant="Body S" sx={{ textTransform: 'none' }}>
              {label}
            </Typography>
          </Box>
        )}
        {description && (
          <Box sx={{ marginBottom: 0.5 }} id={`description-${props.name}`}>
            <Typography
              variant="Additional"
              color={'principal.grey70'}
              sx={{ textTransform: 'none' }}
            >
              {description}
            </Typography>
          </Box>
        )}
        <StyledTextField
          {...props}
          inputProps={{
            ...(props.inputProps || {}),
            'data-testid': testid,
          }}
          variant="outlined"
          error={error}
          sx={{ width: getInputWidth(size), ...(props.sx || {}) }}
          ref={ref}
        />
        {extraDescription && (
          <Box sx={{ marginY: 0.5 }} id={`extra-description-label-${props.name}`}>
            <Typography
              variant="Additional"
              color={'principal.grey70'}
              sx={{ textTransform: 'none', fontStyle: 'italic' }}
            >
              {extraDescription}
            </Typography>
          </Box>
        )}
        {error && helperText && (
          <Box sx={{ marginTop: 0.5 }} id={`helper-text-label-${props.name}`}>
            <Typography
              variant="Additional"
              color={'system.red100'}
              sx={{ textTransform: 'none' }}
            >
              {helperText}
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
);

BaseInput.displayName = 'BaseInput';

const getInputWidth = (size: string) => {
  switch (size) {
    case 'small':
      return '136px';
    case 'medium':
      return '228px';
    case 'large':
      return '354px';
    case 'fullWidth':
      return '100%';
    default:
      return '228px'; // Default case if size is not matched
  }
};

export const createBaseInputStyles = ({ theme }: { theme: Theme }) => ({
  // wrapper: inside are label, input, and outline (border around input)
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    height: 45,
    backgroundColor: theme.palette.principal.grey30,
    ...theme.typography['Body M'],
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.principal.grey30,
  },
  // we show outline only for focused state
  '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.principal.grey70,
    borderWidth: '1px',
  },
  '& .MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.system.red100,
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    height: 45,
    boxSizing: 'border-box',
    ...theme.typography['Body M bold'],
  },
  '& .MuiInputBase-root.Mui-disabled .MuiInputBase-input': {
    color: theme.palette.principal.grey70,
    WebkitTextFillColor: 'unset',
  },
  '& .MuiInputBase-root.Mui-error.MuiInputBase-multiline': {
    backgroundColor: theme.palette.system.red10, // Error background for multiline
    ...theme.typography['Body M'],
  },
  '& .MuiInputBase-root.Mui-error': {
    backgroundColor: theme.palette.system.red10,
    color: theme.palette.principal.grey70,
    ...theme.typography['Body M'],
  },
  // multiline wrapper
  '& .MuiInputBase-root.MuiInputBase-multiline': {
    height: 'auto',
    padding: 0,
    marginRight: theme.spacing(2),
  },
  '& .MuiInputBase-root.MuiInputBase-multiline .MuiInputBase-input': {
    margin: theme.spacing(1, 1, 1, 2),
  },

  '& .MuiInputBase-root .MuiInputBase-input::placeholder': {
    color: theme.palette.principal.grey70,
    ...theme.typography['Body M'],
    opacity: 1,
  },
  '& .MuiInputBase-root.Mui-error .MuiInputBase-input::placeholder': {
    color: theme.palette.principal.grey70,
  },
});

const StyledTextField = styled(TextField)(createBaseInputStyles);
